<template>
  <div class="content">
    <tabs background="black" color="#ccec44" title-inactive-color="#fff" title-active-color="#ccec44" @click="changeTab">
      <tab :title="$t('nft1')" name="shop">
        <div class="nftList">
          <div class="nftCard" v-for="(item, index) in nftList" :key="index">
            <div class="nftCard-inner">
              <img :src="item.img" alt="">
              <div class="nftCard-inner-body">
                <div class="nftCard-inner-body-name">{{item.name}}</div>
                <div class="nftCard-inner-body-desc">{{item.description}}</div>
                <div class="nftCard-inner-body-price">
                  <span>{{item.price}} EGA</span>
                  <span>{{item.num}}/{{item.circulation}}</span>
                </div>
                <div class="nftCard-inner-body-action">
                  <div class="button flex align-items-center justify-content-center" :class="item.num == 0 ? 'notUse':''" @click="doBuy(item)">{{$t('nft3')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab :title="$t('nft2')" name="bag">
        <div class="nftList">
          <div class="nftCard" v-for="(item, index) in mineList" :key="index">
            <div class="nftCard-inner">
              <img :src="item.img" alt="">
              <div class="nftCard-inner-body">
                <div class="nftCard-inner-body-name">{{item.name}}</div>
                <div class="nftCard-inner-body-desc">{{item.description}}</div>
                <div class="nftCard-inner-body-price">
                  <span>{{item.price}} EGA</span>
                  <span>{{item.num}}/{{item.circulation}}</span>
                </div>
                <div class="nftCard-inner-body-action">
                  <div class="button flex align-items-center justify-content-center" @click="doSell(item)">{{$t('nft4')}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-content flex align-items-center justify-content-center" v-if="mineList.length == 0">
            <empty />
          </div>
        </div>
      </tab>
    </tabs>
		<popup v-model="bindShow" :overlay="true" position="bottom" :close-on-click-overlay="false" @close="upAddress=''">
			<div class="popup-bind">
				<div class="popup-bind-title">{{$t('bindup')}}</div>
				<div class="popup-bind-input">
					<input type="text" v-model="upAddress" :placeholder="$t('bindup')">
				</div>
				<div class="popup-bind-btn flex align-items-center justify-content-center" @click="submitBind()">{{$t('confirm')}}</div>
			</div>
    </popup>
		<popup v-model="outShow" :overlay="true" position="bottom" @close="() => {
      toAddress='';
      toNumber='';
      selectItem={};
    }">
			<div class="popup-bind">
				<div class="popup-bind-title">{{$t('nft4')}}</div>
				<div class="popup-bind-input">
					<input type="text" v-model="toAddress" :placeholder="$t('nft5')">
				</div>
				<div class="popup-bind-input">
					<input type="number" v-model="toNumber" :placeholder="$t('nft6')">
				</div>
				<div class="popup-bind-btn flex align-items-center justify-content-center" @click="submitTranfer()">{{$t('confirm')}}</div>
			</div>
    </popup>
  </div>
</template>
<script>
import { Popup, Tab, Tabs, Empty } from 'vant';
import http from '@/utils/http.js'
import Web3 from '@/utils/web3.js'
export default {
  name: 'nft',
	components: {
		Popup,
    Tab,
    Tabs,
    Empty
  },
	data() {
		return {
      address: '',
      adminAddress: '',
      bindShow: false,
      outShow: false,
      upAddress: '',
      nftContract: '',
      nft_instance: '',
      nftList: [],
      mineList: [],
      toAddress: '',
      toNumber: '',
      selectItem: {},
      toContractAddress: '',
      egaContract: '0xD151e5F58B6dAd0EC955e7148735C96F4e3B2ffa',
      ega_instance: '',
      egaBalance: ''
    }
  },
  
	mounted() {
		this.init();
		if (this.$route.query.p) {
			this.upAddress = this.$route.query.p;
		}
  },
	methods: {
    init() {
      Web3.getWeb3().then(async res => {
        this.web3js = res;
        try {
          const result = await window.ethereum.enable();
          this.address = result[0];
          this.$store.commit("setAddress", this.address);
          this.getAccount();
        } catch (error) {
          this.$toast(this.$t('toast13'))
        }
      }).catch(err => {
        this.$toast(err.message)
      })
    },
    getAccount() {
      this.$loading.show();
      http.get('/home/team', { address: this.address }).then(async ({ data }) => {
        this.$loading.hide();
        if (data.errno == 200) {
          this.teamInfo = data.data;
          if (!this.teamInfo.paddress) {
            this.bindShow = true;
          } else {
            this.$loading.show();
            this.adminAddress = this.teamInfo.nft_list;
            this.toContractAddress = this.teamInfo.nft_buy;
            this.nftContract = this.teamInfo.nft;
            const nftabi = require('@/utils/nft.json');
            const egaabi = require('@/utils/ega.json');
            this.nft_instance = await new this.web3js.eth.Contract(nftabi, this.nftContract, {
              from: this.address,
            });
            
            this.ega_instance = await new this.web3js.eth.Contract(egaabi, this.egaContract, {
              from: this.address,
            });

            this.ega_instance.methods.balanceOf(this.address).call().then(v => {
              this.egaBalance = v;
            });
            this.getNftList();
          }
        }
      }).catch((err) => {
        this.$loading.hide();
      })
    },
    getNftList() {
      http.get('/nft/list', { address: this.address }).then(({ data }) => {
        this.$loading.hide();
        if (data.errno == 200) {
          let nftList = data.data;
          let adminAddressArr = [];
          let nftIdArr = [];
          for (let i = 0; i < nftList.length; i++) {
            nftIdArr.push(nftList[i].token_id);
            adminAddressArr.push(this.adminAddress);
          }
          this.nft_instance.methods.balanceOfBatch(adminAddressArr,nftIdArr).call().then(v => {
            for (let i = 0; i < nftIdArr.length; i++) {
              nftList.map(item => {
                if (item.id === nftIdArr[i]) {
                  item.num = v[i];
                  return item;
                }
              });
            }
            this.nftList = nftList;
          });
        }
      }).catch(() => {
        this.$loading.hide();
      })
    },
    changeTab(name) {
      this.$loading.show();
      if (name == 'shop') {
        this.nftList = [];
        this.getNftList();
      } else {
        this.mineList = [];
        this.getMineList();
      }
    },
    getMineList() {
      http.get('/nft/list', { address: this.address }).then(({ data }) => {
        this.$loading.hide();
        if (data.errno == 200) {
          let nftList = data.data;
          let addressArr = [];
          let nftIdArr = [];
          for (let i = 0; i < nftList.length; i++) {
            nftIdArr.push(nftList[i].token_id);
            addressArr.push(this.address);
          }
          this.nft_instance.methods.balanceOfBatch(addressArr,nftIdArr).call().then(v => {
            for (let i = 0; i < nftIdArr.length; i++) {
              nftList.map(item => {
                if (item.id === nftIdArr[i]) {
                  item.num = v[i];
                  return item;
                }
              });
            }
            this.mineList = nftList.filter(item => Number(item.num) > 0);
          });
        }
      }).catch(() => {
        this.$loading.hide();
      })
    },
    submitBind() {
      if (!this.upAddress) return this.$toast(this.$t('alert3'));
      this.$loading.show();
      http.post('/operate/bind', { superior: this.upAddress,address: this.address}).then(({ data }) => {
        this.$loading.hide();
        if (data.errno == 200) {
          this.bindShow = false;
          this.getAccount();
        } else {
          this.$toast(data.errmsg);
        }
      }).catch(err => {
        this.$loading.hide();
      })
    },
    doBuy(info) {
      if (info.num == 0) return;
      if (info.price*Math.pow(10,18) > this.egaBalance) return this.$toast(this.$t('alert5'));
      this.$loading.show();
      http.post('/nft/buy', { mark: info.token_id,address: this.address}).then(({ data }) => {
        if (data.errno == 200) {
          this.sendData(data.data);
        } else {
          this.$loading.hide();
          this.$toast(data.errmsg);
        }
      }).catch(err => {
        this.$loading.hide();
      })
    },
		sendData(data) {
      this.ega_instance.methods.allowance(this.address,this.toContractAddress).call().then(res => {
				if (res == 0) {
					this.ega_instance.methods.approve(this.toContractAddress,'115792089237316195423570985008687907853269984665640564039457584007913129639935').send().then(res => {
						this.transIt(data); 
					}).catch(err => {
						this.$loading.hide();
						this.$toast(err.message)
					})
				} else {
					this.transIt(data); 
				}
			})
		},
    transIt(data) {
      this.web3js.eth.sendTransaction({
        from: this.address,
        to: this.toContractAddress,
        data: data
      }).then(res => {
        this.$loading.hide();
        this.getAccount();
        this.$toast(this.$t('toast10'));
      }).catch(err => {
        this.$loading.hide();
        this.$toast(err.message)
      })
    },
    doSell(info) {
      this.selectItem = info;
      this.outShow = true;
    },
    submitTranfer() {
      if (!this.toAddress) return this.$toast(this.$t('alert3'));
      if (!this.toNumber) return this.$toast(this.$t('alert4'));
      if (Number(this.toNumber) > Number(this.selectItem.num)) return this.$toast(this.$t('alert5'));
      this.$loading.show();
      this.nft_instance.methods.safeTransferFrom(this.address,this.toAddress,this.selectItem.token_id,this.toNumber, []).send().then(v => {
        this.getMineList();
      }).catch(err => {
        this.$loading.hide();
        this.outShow = false;
        this.$toast(err.message)
      });
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  width: 100%;
  min-height: 90vh;
  .empty-content {
    width: 100%;
    padding: 100px 0;
  }
  .nftList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
  }
  .nftCard {
    width: 172px;
    background-color: #000;
    border-radius: 8px;
    border: 1px solid #424242;
    overflow: hidden;
    &-inner {
      padding: 8px;
      gap: 8px;
      display: flex;
      flex-direction: column;
      background: rgba(255, 255, 255, 0.25);
      img {
        width: 100%;
        border-radius: 8px;
      }
      &-body {
        &-name {
          font-size: 12px;
          line-height: 1.3;
          color: rgba(255,255,255, 0.88);
          font-weight: 500;
          margin-bottom: 8px;
        }
        &-desc {
          font-size: 8px;
          line-height: 1.5;
          color: rgba(255,255,255, 0.65);
          margin-bottom: 8px;
        }
        &-price {
          font-size: 10px;
          color: rgba(255,255,255, 0.88);
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &-action {
          margin-top: 8px;
          .button {
            width: 100%;
            height: 30px;
            border-radius: 5px;
            background: #ccec44;
            font-size: 13px;
            color: #000;
            margin-top: 20px;
            &.notUse {
              background: #e9e9e9;
              color: #999;
            }
          }
        }
      }
    }
  }
}
.popup-bind{
	width: 100%;
	padding: 20px;
	padding-bottom: 30px;
	background: #3B3F49;
	&-title{
		font-size: 18px;
		color: #fff;
		font-weight: bold;
		line-height: 40px;
		text-align: center;
	}
	&-input{
		width: 100%;
		height: 44px;
		border-radius: 5px;
		border: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		margin-top: 20px;
		input{
			width: 100%;
			font-size: 12px;
			color: #fff;
		}
	}
	&-btn{
		width: 100%;
		height: 44px;
		border-radius: 5px;
		background: #ccec44;
		font-size: 13px;
		color: #000;
		margin-top: 20px;
	}
}
</style>